<template>
  <div class="card">
    <!--  选择关键词  -->
    <div class="select_key_word">
      <tab :items="searchKeyWord" @sendSelectedItem="selectKeyWord"></tab>
    </div>
    <!--  搜索框  -->
    <div class="selectedInput">
      <van-search v-model.trim="keyWordValue" :placeholder="placeholder" />
    </div>
    <!--  搜索按钮  -->
    <div class="search-button">
      <van-button round type="info" @click="onSearch">
        <template #icon><van-icon name="search" /></template>
        立即查询
      </van-button>
    </div>
  </div>
</template>

<script>
import Tab from './Tab'
import request from '../../../api/request'
import { Toast } from 'vant'
import { orderMessageSort } from '@/views/orderPage/simple/utils'
import { getNowTimeStamp } from '../../../utils/functions'
export default {
  name: 'Card',
  components: {
    Tab
  },
  props: {
    searchKeyWord: Array
  },
  data() {
    return {
      keyWordValue: '', //输入框的值
      placeholder: '', //输入框提示信息
      selectedInfoId: '' //选择的关键字
    }
  },
  watch: {
    searchKeyWord(newProps) {
      this.placeholder = '请输入' + newProps[0].text
      this.selectedInfoId = newProps[0].infoId
    }
  },
  methods: {
    selectKeyWord(keyWord) {
      //选择关键字
      this.placeholder = '请输入' + keyWord.text
      this.selectedInfoId = keyWord.infoId
    },
    onSearch() {
      //搜索事件,回调参数当前输入的值
      if(this.selectedInfoId===undefined||this.selectedInfoId===null||String(this.selectedInfoId).trim().length<=0){
        Toast.fail('selectedInfoId 不能为null！')
        return;
      }
      if(this.keyWordValue===undefined||this.keyWordValue===null||String(this.keyWordValue).trim().length<=0){
        Toast.fail('请填写查询信息！')
        return;
      }
      if(this.$route.params.templateId===undefined||this.$route.params.templateId===null||String(this.$route.params.templateId).trim().length<=0){
        Toast.fail('templateId 不能为null！')
        return;
      }
      request
        .get(
          `/payment/wx/school/order?infoId=${this.selectedInfoId}&infoValue=${this.keyWordValue}&templateId=${this.$route.params.templateId}`
        )
        .then((res) => {
          if (res.size <= 0) {
            // 清空搜索记录
            this.$emit('emitOrderMessageSort', [])
            this.$store.commit('setOrderMessages', [])
            Toast.fail('未找到订单！')
          } else {
            let orderMessages = orderMessageSort(res.content)
            for (let i = 0; i < orderMessages.length; i++) {
              orderMessages[i].listID = getNowTimeStamp() + i
            }
            this.$emit('emitOrderMessageSort', orderMessages)
            this.$store.commit('setOrderMessages', orderMessages)
          }
        })
        .catch((err) => {
          Toast.fail(err.message)
        })
    }
  },
  mounted() {}
}
</script>

<style scoped lang="less">
.card {
  .select_key_word {
    /deep/.van-tab {
      display: flex;
      justify-content: start;
    }
    padding-bottom: 5px;
    border-bottom: #e5e5e5 solid 1px;
  }
  .selectedInput {
    margin-top: 22px;
  }
  .search-button {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    font-size: 13px;

    .van-button--round {
      width: 265px;
      height: 34px;
      border-radius: 17px;
    }
  }
}
</style>
